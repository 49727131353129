import React, { Component } from 'react'
import Store from './store'
import gsap from 'gsap'

export default class Quickbuy extends Component {

    state = {
        product: JSON.parse(this.props.product),
        quantity: 1,
        current_size: 0,
        //popup_open: false
    }

    async componentDidMount() {
        let c_variant = this.state.product.sizes.filter((e) => e.qty > 0)[0]
        if (c_variant) {
            this.setState({
                current_size: this.state.product.sizes.indexOf(c_variant)
            })
        } else {
            this.checkSize()
        }



    }

    checkSize() {
        console.log("CHECK SIZE")
        const size = this.state.product.sizes[this.state.current_size].qty <= 0
        if (size) {
            this.setState({
                cart_cta: this.state.stock_cta
            })
        } else {
            this.setState({
                cart_cta: this.state.add_cta
            })
        }

    }
    async postData(url = "", data = {}) {
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
                    .content,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
        });
        return response.json();
    }
    async setSize(el) {
        await this.setState({
            current_size: this.state.product.sizes.findIndex(x => x.size === el.target.value),
            quantity: 1
        })

        this.checkSize()
    }
    async addToCart(e) {
        e.preventDefault()
        if (this.state.quantity === 0 || this.state.product.sizes[this.state.current_size].qty == 0 || this.state.product.sizes[this.state.current_size].qty < 0) return





        let itemToAdd = {
            "id": this.state.product.sizes[this.state.current_size].id,
            "price": this.state.product.item_price,
            "quantity": this.state.quantity,
        }

        const url = "/en/cart/add_to_cart"
        this.postData(url, itemToAdd).then((data) => {
            window.dispatchEvent(new CustomEvent("cart-add-item"));
        })




    }


    render() {
        return (
            <>

                {this.props.alt ? (
                    <div className='sample_cont'>
                        <button
                            className="button h8 upcase brown bg-sky c-light button-medium sample"
                            onClick={this.addToCart.bind(this)}
                        >
                            {this.props.cta} <span className="sample_price">{"€" + JSON.parse(this.props.product).item_price}</span>
                        </button>
                    </div>
                ) : (
                    <button
                        className="buy_now_btn"
                        onClick={this.addToCart.bind(this)}
                    >
                        <img loading="lazy" src="/icons/basket_buy_now.svg" alt="" />
                    </button>
                )}

                {/*
          this.state.popup_open && (
              <section className="shop-quickbuy">
                  <div className="shop-quickbuy-popup">
                      <img className="closeX" src="/icons/Close.svg" alt="" />
                      <div className="shop-quickbuy-content">
                          <div className="shop-quickbuy-sidebar">
                            {
                              this.state.product_images.length > 0 && this.state.product_images.map((image,i) => (
                                <div><img src={image.image.thumb.url} alt="" /></div>
                              ))
                            }
                          </div>

                          <div className="shop-quickbuy-carousel">
                              <div className="swiper1Col swiper">
                                  <div className="swiper-wrapper">
                                      {
                                        this.state.product_images.length > 0 && this.state.product_images.map((image,i) => (
                                          <div className="swiper-slide" key={i}>
                                            <div className="home-carousel-3slide-card">
                                                <div className="image-container">
                                                    <img className="center" src={image.image.url} alt="" />
                                                </div>
                                            </div>  
                                        </div>
                                        ))
                                      }
  

                                  </div>
                                  <div className="swiper-button-prev"></div>
                                  <div className="swiper-button-next"></div>
                              </div>
                          </div>
                      </div>
                      <div className="shop-quickbuy-center">
                      <div className="add-to-cart">
                          <div className="add-to-cart_prod-name">
                            <h2 className="h5 sainte medium c-dark text-center">{this.state.product_complete.legacy_data.title}</h2>

                          </div>
                          <div 
                              className="js-add_to_cart"
                              data-onesize="<%= @product.onesize? %>"
                              data-translations="<%= t('add_to_cart').to_json %>"
                              data-stock="<%= @product.stock %>" 
                              data-colors="<%= @colors.to_json %>"
                              data-product="<%= @cart_product.to_json %>">ADD TO BAG</div>
                      </div>
                      </div>
                  </div>
              </section>






          )
                                    */}

            </>

        )
    }
}
