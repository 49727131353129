import { qs, qsa } from "../utils/lib";
import QuickBuy from "../components/quick_buy.jsx";
import defineComponents from "../utils/define.jsx";


export default class infiniteScroll {
    constructor(el) {
        this.el = el
        this.nexturl
        this.btn = qs('.cta_infinite')
        this.ended = false
        this.spinner = qs(".spinner")
        this.loading = false
        this.window = window
        this.init()
    }

    init() {
        const next = this.el.querySelector(".next")
        if (next) {
            this.nexturl = next.querySelector("a").getAttribute("href")
            this.startScroll()
        } else {
            this.ended = true
        }
    }

    startScroll() {
        window.addEventListener('scroll', () => {
            if (this.ended) {
                return false
            }
            let scroll = this.window.scrollY
            let height = document.documentElement.scrollHeight;
            let window = this.window.innerHeight;
            let bottom_scroll = scroll
            let target = height - window - 5000
            if (bottom_scroll > target) {
                this.getPage()
            }
        });
    }

    getPage() {
        if (this.ended || this.loading) {
            return false
        }
        this.loading = true
        const spinner = this.spinner
        spinner.classList.add("loading")
        fetch(this.nexturl).then((response) => {
            if (response.ok) {
                return response.text();
            }
            throw response;
        }).then((text) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(text, 'text/html');
            let html = doc
            let content = html.querySelectorAll('.products-grid_col');
            let dialog = document.querySelector('#product-cont');
            let next = html.querySelector(".next")
            content.forEach((div) => {
                dialog.append(div);
            })
            this.loading = false
            if (next) {
                console.log(next)
                this.nexturl = next.querySelector("a").getAttribute("href")
            } else {
                this.ended = true
            }
            spinner.classList.remove("loading")
            new defineComponents(
                {

                    ".buy_now:not(.loaded)": QuickBuy,
                    ".buy_now_b2b:not(.loaded)": QuickBuy,
                },
                () => { }
            ).load();
        });

    }
}
