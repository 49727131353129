import React, { Component } from 'react'
import Store from './store'
import _ from 'lodash'
import { qs, qsa } from '../utils/lib';
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import QuickBuy from "./quick_buy.jsx";

export default class AddToCart extends Component {

    state = {
        product: JSON.parse(this.props.product),
        translations: JSON.parse(this.props.translations),
        colors: JSON.parse(this.props.colors),
        add_cta: "Add to Trolley",
        added_cta: "Voilà",
        stock_cta: "Out of stock",
        cart_cta: "Add to Trolley",
        quantity: 1,
        othersizes: JSON.parse(this.props.othersizes),
        current_size: 0,
        prompt: true,
        size_guide: JSON.parse(this.props.sz_guide),
        usages: JSON.parse(this.props.usages)
    }


    async componentDidMount() {
        let c_variant = this.state.product.sizes.filter((e) => e.qty > 0)[0]
        if (c_variant) {

            this.setState({
                current_size: this.state.product.sizes.indexOf(c_variant)
            })
        } else {
            this.checkSize()

        }

        if (this.props.onesize == "true") {
            this.setState({
                prompt: false
            })
        }


        this.setButton()

    }

    checkSize() {

        const size = this.state.product.sizes[this.state.current_size].qty <= 0
        if (size) {
            this.setState({
                cart_cta: this.state.stock_cta
            })
        } else {
            this.setState({
                cart_cta: this.state.add_cta
            })
        }


    }

    async setSize(el) {

        if (this.state.prompt) {

        }
        await this.setState({
            prompt: false,
            current_size: this.state.product.sizes.findIndex(x => x.size === el.target.value),
            quantity: 1
        })

        this.checkSize()
    }
    async postData(url = "", data = {}) {
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
                    .content,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
        });
        return response.json();
    }

    async addItem(e) {
        // product, price, quantity = 1)
        if (this.state.quantity === 0 || this.state.product.sizes[this.state.current_size].qty == 0 || this.state.product.sizes[this.state.current_size].qty < 0 || this.state.prompt) {
            this.setState({
                "notice": this.state.translations.notice
            })
            return false
        }
        this.setState({
            "notice": false
        })

        if (typeof gtag !== 'undefined') {
            gtag('event', 'add_to_cart', {
                "items": [this.state.product.to_google]
            });
            gtag('event', 'add', {
                'event_category': 'cart'
            });
        }

        // product, price, quantity = 1)

        let itemToAdd = {
            "id": this.state.product.sizes[this.state.current_size].id,
            "price": this.state.product.item_price,
            "quantity": this.state.quantity,
        }

        const url = "/en/cart/add_to_cart"
        this.postData(url, itemToAdd).then((data) => {
            window.dispatchEvent(new CustomEvent("cart-add-item"));
        })






        setTimeout(() => {
            this.setState({
                cart_cta: this.state.add_cta
            })
        }, 3000)

    }
    async addItemAll(e) {
        const inputs = document.querySelector(".sizes_table").querySelectorAll("input")
        let items = []
        inputs.forEach((el, i) => {
            if (!el.value || parseInt(el.value) == 0) {
                return false
            }
            console.log(i)
            console.log(this.state.product.sizes[i].id)
            let itemToAdd = {
                "id": this.state.product.sizes[i].id,
                "price": this.state.product.item_price,
                "quantity": parseInt(el.value),
            }
            items.push(itemToAdd)
        })
        const url = "/en/cart/m_add_to_cart"
        let body = {
            "items": items
        }
        console.log(body)
        this.postData(url, body).then((data) => {
            window.dispatchEvent(new CustomEvent("cart-add-item"));
        })
        setTimeout(() => {
            this.setState({
                cart_cta: this.state.add_cta
            })
        }, 3000)
    }

    getDiscountPerc(full_price, price) {
        const value = 100 - ((price * 100) / full_price)
        return `-${value.toFixed(2)}%`
    }
    less() {

        if (this.state.quantity == 1) {
            return
        }
        this.setState({
            quantity: this.state.quantity -= 1
        })
        document.querySelector('.js-quantity').innerHTML = this.state.quantity
        this.setButton()
    }

    more() {
        console.log(this.state.product.sizes[this.state.current_size].qty, this.state.quantity)
        if (this.state.quantity + 1 > this.state.product.sizes[this.state.current_size].qty) {
            return
        }
        this.setState({
            quantity: this.state.quantity += 1
        })
        document.querySelector('.js-quantity').innerHTML = this.state.quantity
        this.setButton()
    }
    redirect(e) {
        window.location.href = e.target.value;
    }

    anchorTo(e) {
        const btn = e.target
        const anchor = btn.dataset.id
        gsap.registerPlugin(ScrollToPlugin)
        gsap.to(window, { scrollTo: `#${anchor}` });
    }
    setButton() {


    }
    validate(e) {
        const val = e.target.value
        const max = parseInt(e.target.getAttribute("max"))
        console.log(max, val)
        if (val > max) {
            console.log("ok")
            e.target.value = max
        }
    }


    render() {

        return (
            <div className="add-to-cart_options-grid">
                <div className="add-to-cart_grid-row add-to-cart_grid-row-price">
                    <h4 className="h6 brown c-dark">€ {this.state.product.item_price.toFixed(2)}</h4>
                    {this.state.product.compare_price > this.state.product.item_price && (
                        <h5 className="add-to-cart_sales-price h7 brown c-notActive">
                            <span className="brown light line-through">€ {this.state.product.compare_price.toFixed(2)}</span> {this.getDiscountPerc(this.state.product.compare_price, this.state.product.item_price)}
                        </h5>
                    )
                    }
                </div>
                {
                    this.state.colors.length > 0 && (
                        <div className="add-to-cart_grid-row">
                            <label className="add-to-cart_row-label h8 brown c-dark">{this.state.translations.color}:</label>
                            {
                                this.state.colors.map((color, i) => (
                                    <a href={`${color.slug}`} className={"add-to-cart_color-swatch add-to-cart_color-swatch-active "}>

                                        <span className="add-to-cart_color-swatch-inside">
                                            <img src={color.img} alt="" />
                                        </span>
                                    </a>
                                ))
                            }
                        </div>
                    )
                }
                {
                    this.state.product.show_material && this.props.show_material == "true" && (
                        <div className="add-to-cart_grid-row">
                            <label className="add-to-cart_row-label h8 brown c-dark">{this.state.translations.materials}:</label>
                            <p className="h8 brown c-dark light">{this.props.material}</p>


                            <div className="add-to-cart_align-end">
                                <a href="#" className="js-button-atc button-link button-link-small brown h7" data-pop="0">{this.state.translations.m_guide}</a>
                            </div>
                        </div>
                    )
                }
                {
                    this.state.product.show_material && this.props.show_material == "false" && (
                        <div className="add-to-cart_grid-row">
                            <label className="add-to-cart_row-label h8 brown c-dark">{this.state.translations.materials}:</label>
                            <p className="h8  brown c-dark light">{this.props.material}</p>



                        </div>
                    )
                }
                {this.props.sampleproduct && (
                    <div className="add-to-cart_grid-row">

                        <details className='add_to_cart_acc'>
                            <summary className='h8 brown c-dark'>
                                {this.props.sample_title}
                            </summary>
                            <p className='h8  brown c-dark light'>{this.props.sample_body}</p>
                        </details>


                    </div>

                )}
                {
                    this.state.product.sizes.length > 0 ? (
                        <>
                            {
                                this.props.multiple == "true" && (
                                    <table className="sizes_table" ref="sizes">
                                        <tr>
                                            {this.state.product.sizes.length > 1 && (
                                                <th>Size</th>
                                            )}
                                            <th>Quantity</th>
                                        </tr>
                                        {this.state.product.sizes.map((el, i) => (
                                            <tr>

                                                {this.state.product.sizes.length > 1 && (
                                                    <td className="brown">{el.size}</td>
                                                )}
                                                <td className="brown"><input type="number" name={el.id} placeholder="0" min="0" max={el.qty} onKeyUp={this.validate.bind(this)} /></td>
                                            </tr>
                                        ))}
                                    </table>
                                )
                            }

                            < div className="add-to-cart_grid-row">
                                {this.props.multiple != "true" && (
                                    <>
                                        <label className="add-to-cart_row-label h8 brown c-dark">{this.state.translations.size}:</label>
                                        <select
                                            onChange={this.setSize.bind(this)}
                                            className="h8 brown light c-dark add_to_cart__select"
                                            disabled={this.props.stock == 0}
                                        >
                                            <option value="default" selected="selected" disabled>{this.state.translations.choose}</option>
                                            {
                                                this.state.product.sizes.map((el, i) => (
                                                    <option
                                                        key={i}
                                                        value={el.size}
                                                        disabled={el.qty < 1 ? true : null}>{el.size.replace('U', 'One Size')}
                                                    </option>
                                                ))
                                            }
                                        </select>

                                    </>

                                )}
                                {
                                    this.state.size_guide && (
                                        <div className="add-to-cart_align-end">
                                            <button className="js-button-atc button-link button-link-small brown h7" data-pop="1">{this.state.translations.s_guide}</button>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                    ) : (
                        <div className="add-to-cart_grid-row">
                            <label className="add-to-cart_row-label h8 brown c-dark">{this.state.translations.size}:</label>
                            {this.state.othersizes.length > 1 ? (
                                <select
                                    onChange={this.redirect.bind(this)}
                                    className="h8 brown light c-dark add_to_cart__select"
                                >

                                    {
                                        this.state.othersizes.map((el, i) => (
                                            <option
                                                key={i}
                                                value={el.slug}
                                                selected={el.name == this.props.size_label}
                                            >{el.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            ) : (
                                <p className="h8 brown c-dark light">{this.props.size_label ? this.props.size_label : this.state.product.sizes[this.state.current_size].size}</p>
                            )}

                            {
                                this.state.size_guide && this.state.product.show_material && (
                                    <div className="add-to-cart_align-end">
                                        <button className="js-button-atc button-link button-link-small brown h7" data-pop="1">{this.state.translations.s_guide}</button>
                                    </div>
                                )
                            }
                        </div>
                    )
                }



                {(this.props.multiple != "true" && this.state.product.sizes.length == 1) && (
                    <div className="add-to-cart_grid-row">
                        <label className="add-to-cart_row-label h8 brown c-dark ">{this.state.translations.qty}:</label>
                        <div className="add-to-cart_quantity-buttons">
                            <button
                                onClick={this.less.bind(this)}
                                className="add-to-cart_quantity-buttons--decrease"
                                disabled={this.props.stock == 0}
                            >
                                <img loading="lazy" src="/icons/minus_circle.svg" alt="" />
                            </button>
                            <span className={"js-quantity brown light h7 " + (this.props.stock == 0 && "disabled")}>1</span>
                            <button
                                onClick={this.more.bind(this)}
                                className="add-to-cart_quantity-buttons--increase"
                                disabled={this.props.stock == 0}
                            >
                                <img loading="lazy" src="/icons/plus_circle.svg" alt="" />
                            </button>
                        </div>
                        {
                            this.props.stock == 0 && (
                                <p className="add-to-cart_align-end h8 sainte light-italic c-notActive">{this.state.translations.out_s}</p>
                            )
                        }
                        {
                            this.state.product.sizes[this.state.current_size].qty < 2 && this.state.product.sizes[this.state.current_size].qty > 0 && (
                                <p className="add-to-cart_align-end h8 sainte light-italic c-accent2 capitalize">{`${this.state.translations.only} ${this.state.product.sizes[this.state.current_size].qty} ${this.state.translations.items_left}`}</p>
                            )
                        }
                    </div>
                )}
                {
                    this.state.usages && (
                        <div className="add-to-cart_grid-row">
                            <label className="add-to-cart_row-label h8 brown c-dark capitalize">{this.state.translations.style}:</label>
                            <div className="add-to-cart_style-grid">
                                {
                                    this.state.usages.length > 1 && (
                                        <div className="add-to-cart_style-col add-to-cart_style-col-text">
                                            <p className="brown h9 light">{this.state.translations.style_t}</p>
                                        </div>

                                    )
                                }
                                {this.state.usages.map((el) => (
                                    <div className="add-to-cart_style-col">
                                        <img loading="lazy" src="/icons/ticked.svg" alt="" className="add-to-cart_style-icon" />
                                        <p className="brown light h9">{el}</p>
                                    </div>
                                ))}

                            </div>
                        </div>

                    )
                }

                {
                    this.props.stock < 1 ? (
                        <div className="add-to-cart_grid-row add-to-cart_grid-row-buttons">
                            <button className="button h8 upcase brown button-bg-transparent button-medium js-out-of-stock">{this.state.translations.notify}</button>
                        </div>
                    ) : (
                        <div className="add-to-cart_grid-row add-to-cart_grid-row-buttons">
                            {this.props.sampleproduct && (
                                <QuickBuy alt="1" product={this.props.sampleproduct} cta={this.props.sample_title} />

                            )}
                            <button className="button h8 upcase brown button-bg-visible button-medium" onClick={this.props.multiple == "true" ? this.addItemAll.bind(this) : this.addItem.bind(this)}>{this.state.translations.cta}</button>

                            <p className="notice">
                                {this.state.notice}
                            </p>
                        </div>
                    )
                }
                <div className="add-to-cart_grid-row add-to-cart_grid-row-buttons">
                    <button data-id="js-anchor" onClick={this.anchorTo.bind(this)} className="button-link button-link-small brown h7">{this.state.translations.read_more}</button>
                </div>
            </div >
        )
    }
}
