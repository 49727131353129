//Style
import "../../scss/application.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

//Libraries
import gsap from "gsap";
import Sortable from "sortablejs";

//Components - Functions
import QuickBuyB2b from "../components/quick_buy_b2b";
import StaticNl from "../components/nl_static";
import Sync from "../components/cart_sync";
import DropDown from "../components/dropdown.js";
import MobileMenuNav from "../components/mobile_menu_nav.js";
import { qs, qsa, setBodyFixed } from "../utils/lib.js";
import defineComponents from "../utils/define.jsx";
import Minicart from "../components/minicart.jsx";
import AddToCart from "../components/add_to_cart.jsx";
import AltAddToCart from "../components/_alt_add_to_cart.jsx";
import AddToCartB2b from "../components/add_to_cart_b2b.jsx";
import ReturnForm from "../components/return_form.jsx";
import QuickBuy from "../components/quick_buy.jsx";
import ScrollFn from "../components/scroll";
import StateSelect from "../components/country_select";
import Autocomplete from "../components/autocomplete";
import productImage from "../components/product_images.js";
import NewsletterPopup from "../components/popup/NewsletterPopup.js";
import SliderProduct from "../components/slider_product";
import { NavbarMain } from "../components/navbar/navbarMain";
import HomeEmporium from "../components/home-emporium";
import faqAccordion from "../components/faq-accordion";
import NewCart from "../components/new_cart";
import Store from '../components/store'
import {
    swiper1Col,
    swiper3Cols,
    swiper5Cols,
} from "../components/carousels/carousel.js";
import Carousel3Slide from "../components/carousels/carousel3slide.js";
import infiniteScroll from "../components/infinite_scroll.js";
import FiltersPopup from "../components/popup/FiltersPopup";
import SizeGuideInitiator from "../components/shop_sizeGuide";
import OutOfStockPopup from "../components/popup/OutOfStockPopup";
import Recap from "../components/recap";
import LanguageRedirect from "../components/language";
import Accordion from "../components/accordion";
import OrderProducts from "../components/order_products";
import { termsScroller } from "../components/pages/terms";
import CheckoutInfo from "../components/checkout_info";
import PaymentPage from "../components/payment_page";
import CheckoutAccordionMobile from "../components/checkout_accordion";
import HomeSlider from "../components/home-slider";
import { aboutTriggers } from "../components/pages/about";
import IntersectionObs from "../components/inter";
import ProductZoom from "../components/zoom.js";
// import { infiniteScroll, PostcardSlider} from "../components/pages/postcards";
import PostcardsClick from "../components/pages/postcards";
import ReportPage from "../components/report-page";
import getTheLook from "../components/getTheLook";
import B2bRecap from "../components/new_recap";
require("intersection-observer");

const newApp = () => {

    const nc = qs(".js-cart");
    if (nc) {
        const c = new NewCart();
        c.init();
    }

    const b2b_recap = qs(".js-b2b-recap");
    if (b2b_recap) {
        const c = new B2bRecap();
        c.init()
    }
    const pagination = qs('.pagination_container')
    if (pagination) {
        const ic = new infiniteScroll(pagination)
    }
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const resizeVH = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", resizeVH);

    const select = qs(".js-language");
    select && LanguageRedirect(select);
    NavbarMain();
    const home_sl = qs(".js-home-hero-slider");
    if (home_sl) {
        HomeSlider(home_sl);
    }

    const p = qs("#product-page");
    if (p) {
        ProductZoom();
    }
    // Carousels

    if (".swiper1Col") swiper1Col();
    if (".js_carousel_3slide") swiper3Cols();
    if (".js_carousel_5slide") swiper5Cols();

    //Newsletter Popup
    if (qs(".newsletter-popup")) {
        const newsPopup = new NewsletterPopup();
        newsPopup.init();
    }

    const payment_page = qs(".js-payments");
    if (payment_page) {
        PaymentPage();
    }

    if (qs(".static_nl")) {
        const nl = new StaticNl(qs(".static_nl"));
        nl.init();
    }
    //Shop quick buy Popup
    if (qs(".filters-popup")) {
        const filtersPopup = new FiltersPopup();
        filtersPopup.init();
    }

    //Size Guide Popup
    const atcUi = qsa(".add-to-cart");
    atcUi.forEach((el) => {
        const c = new SizeGuideInitiator(el);
        c.init();
    });

    //Out of stock Popup
    if (qs(".js-out-of-stock")) {
        const outOfStockPopup = new OutOfStockPopup();
        outOfStockPopup.init();
    }

    // 3col carousel
    if (qs(".js_carousel_3slide")) {
        const switchCarousel = new Carousel3Slide(qs(".js_carousel_3slide"));
        switchCarousel.init();
    }

    //Home Emporium
    if (qs(".js-dot-filters")) {
        const dotfiltersbar = new HomeEmporium(qs(".js-dot-container"));
        dotfiltersbar.init();
    }

    // Faq page
    if (qs(".faq-accordion")) {
        const faqAccordionList = qsa(".faq-accordion");
        faqAccordionList.forEach((el) => {
            const faqAcc = new faqAccordion(el);
            faqAcc.init();
        });
    }

    //Accordion
    if (".js-accordion") {
        const accordions = qsa(".js-accordion");
        accordions.forEach((accordion) => {
            const acc = new Accordion(accordion);
            acc.init();
        });
    }

    const product_overview = qs(".product-overview");
    if (product_overview) {
        const slider = new SliderProduct();
        slider.init();
    }

    //Terms
    if (qs(".terms-container")) {
        termsScroller();
    }

    //Selectr
    const select_search = qs(".js-select-product");
    if (select_search) {
        const selectr = new OrderProducts(
            select_search,
            "js-select-product-style"
        );
        selectr.init();
    }

    // checkout accordion mobile
    const checkout_accordion = qs(".js-accordion-checkout");
    if (checkout_accordion) {
        const checkout_acc = new CheckoutAccordionMobile(checkout_accordion);
        checkout_acc.init();
    }

    if (qs(".postcard-grid")) {
        // infiniteScroll()
        const postcards = new PostcardsClick(qs(".postcard-grid"));
        postcards.init();
    }
    //About
    const triggers = qsa("[data-trigger]");
    if (triggers.length >= 1) {
        aboutTriggers();
        new IntersectionObs();
    }

    // always start from top when About pages
    if (qs(".about-hero")) {
        window.onbeforeunload = () => {
            if (window.scrollTo) window.scrollTo(0, 0);
        };
        window.onload = () => {
            if (history && history.scrollRestoration)
                history.scrollRestoration = "manual";
        };
    }
};

const oldApp = () => {
    const cycle = qs(".cycle-js");

    if (cycle) {
        let active = 0;
        let slides = cycle.querySelectorAll("p");
        let total = slides.length;
        const assignSlide = () => {
            slides.forEach((slide, i) => {
                if (i == active) {
                    slide.classList.add("active");
                } else {
                    slide.classList.remove("active");
                }
            });
        };
        assignSlide();
        window.setInterval(() => {
            if (active == total - 1) {
                active = 0;
            } else {
                active++;
            }
            assignSlide();
        }, 6000);
    }

    const editor = document.querySelector(".product_related_editor");
    if (editor) {
        const a = editor.querySelector(".droppable_list_one");
        const b = editor.querySelector(".droppable_list_two");
        const select = document.querySelector("#edit_select");
        const saver = document.querySelector(".js_save_ordering");
        select.addEventListener("change", () => {
            window.location = select.value;
        });
        saver.addEventListener("click", () => {
            const els = a.querySelectorAll(".col");
            let ids = [];
            els.forEach((el) => {
                ids.push(el.dataset.id);
            });
            alert(ids);
        });
        new Sortable(a, {
            group: "shared", // set both lists to same group
            animation: 150,
            draggable: ".col",
        });

        new Sortable(b, {
            group: "shared",
            animation: 150,
            draggable: ".col",
        });
    }

    const loader = qs(".loader");
    gsap.to(loader, {
        autoAlpha: 0,
    });
    const banner = qs(".js-banner");
    if (banner) {
        const items = JSON.parse(banner.dataset.items);
        let active = 0;
        if (items.length > 1) {
            setInterval(function () {
                let next = 0;
                if (active == items.length - 1) {
                    next = 0;
                } else {
                    next = active + 1;
                }
                active = next;
                const tl = gsap.timeline();
                tl.to(banner, {
                    opacity: 0,
                    onComplete: () => {
                        banner.innerHTML = items[active];
                    },
                });
                tl.to(banner, {
                    opacity: 1,
                });
            }, 3000);
        }
    }
    const product_image = qs(".js-product-image");
    if (product_image) {
        const pi = new productImage(product_image);
        pi.init();
    }
    const auto = qs(".autocomplete");
    if (auto) {
        const ac = new Autocomplete(auto);
        ac.init();
    }

    const mmenu = qsa(".menu_slider");
    mmenu.forEach((drop) => {
        new MobileMenuNav(drop);
    });
    const drops = qsa(".dropdown");
    drops.forEach((drop) => {
        new DropDown(drop);
    });
    new defineComponents(
        {
            ".minicart": Minicart,
            ".buy_now": QuickBuy,
            ".buy_now_b2b": QuickBuyB2b,
            ".js-add_to_cart": AddToCart,
            ".js-alt_add_to_cart": AltAddToCart,
            ".js-add_to_cart_b2b": AddToCartB2b,
            ".return_form": ReturnForm,
            ".js-recap": Recap,
            ".jsx-checkout-info": CheckoutInfo,
        },
        () => { }
    ).load();
    const different = qs(".order_different_billing");
    if (different) {
        different.addEventListener("change", function () {
            const target = qs(".js-billing ");
            target.classList.toggle("hidden");
        });
    }
    const SetHeaderToCheckout = (el) => {
        el.value = localStorage.getItem("lc_cart");
        if (JSON.parse(el.value).length == 0) {
            window.location = "/en";
        }
    };

    const cart_el = document.querySelector("#order_cart");
    if (cart_el) {
        SetHeaderToCheckout(cart_el);
    }
    const c = document.querySelector(".country.input");
    if (c) {
        new StateSelect();
    }

    const filters = document.querySelector("#filters_form");
    if (filters) {
        document
            .querySelector("#ordering_select")
            .addEventListener("change", () => {
                filters.submit();
            });
        const sizes = document.querySelector("#sizes_select");
        if (sizes) {
            document
                .querySelector("#sizes_select")
                .addEventListener("change", () => {
                    filters.submit();
                });
        }
    }

    const search_btns = qsa(".js-search");
    const search_menu = qs(".search_container");

    if (search_menu) {
        search_menu.classList.remove("open");
    }

    if (search_btns) {
        search_btns.forEach((search) => {
            search.addEventListener("click", () => {
                search_menu.classList.toggle("open");
                const close_menu_btns =
                    search_menu.querySelectorAll(".js-close");
                if (close_menu_btns) {
                    close_menu_btns.forEach((btn) => {
                        btn.addEventListener("click", () => {
                            search_menu.classList.remove("open");
                        });
                    });
                }
            });
        });

        const mmenu = qs(".mobile_menu");
        if (mmenu) {
            let menu_open = false;
            const btn = qs(".js_ham");
            btn.addEventListener("click", (e) => {
                if (menu_open) {
                    menu_open = false;
                    btn.classList.remove("open");
                    mmenu.classList.remove("open");
                } else {
                    menu_open = true;
                    btn.classList.add("open");
                    mmenu.classList.add("open");
                }
            });
        }
    }
    const report_page = qs(".js-report");
    report_page && new ReportPage(report_page);

    const gtls = document.querySelectorAll(".js-get-the-look");
    gtls.forEach((el) => new getTheLook(el));
};


document.addEventListener("DOMContentLoaded", () => {
    const reset = document.querySelector("body").dataset.cartPresent == "false";
    const cart = new Sync(reset);
    if (localStorage.getItem('lc_cart')) {
        Store.set("cart_items", JSON.parse(localStorage.getItem('lc_cart')))
    }
    oldApp();
    newApp();
});
