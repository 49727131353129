
import { qs, qsa } from "../utils/lib";

export default class NewCart {
    constructor() {
        this.container = qs(".header-cart");
        this.opener = qs(".js-cart");
        this.close_btn = qs(".closeX");
        this.wrapper = this.container.querySelector(".header-cart_wrapper");
        this.cart_url = this.container.getAttribute("data-url");
        this.info_url = this.container.getAttribute("data-info");
        this.isupdating = false;
        this.subtotal = qs(".cart_subtotal");
        this.cartCounter = qs(".new_mini_cart_counter span");
        this.more = qsa(".cart_more");
        this.less = qsa(".cart_less");
        this.deleters = qsa(".cart_remove");

    }


    init() {
        this.updateCart();
        this.opener.addEventListener("click", () => {
            this.open();
        });
        window.addEventListener("cart-add-item", () => {
            this.openAndClose();
        })
        this.close_btn.addEventListener("click", () => {
            this.close();
        })
    }

    openAndClose() {
        this.open();
        window.setTimeout(() => {
            this.close();
        }, 2000);
    }
    bindActions() {

        this.more = qsa(".cart_more");
        this.less = qsa(".cart_less");
        this.deleters = qsa(".cart_remove");

        this.more.forEach(element => {
            element.addEventListener("click", (e) => {
                e.preventDefault();
                this.increase(element)
            });
        })
        this.less.forEach(element => {
            element.addEventListener("click", (e) => {
                e.preventDefault();
                this.decrease(element)
            });
        })
        this.deleters.forEach(element => {
            element.addEventListener("click", (e) => {
                e.preventDefault();
                this.removeEl(element)
            });
        })
    }
    decrease(e) {
        if (this.is_updating) {
            return false
        }
        this.removeItem(e);
    }

    increase(e) {
        if (this.is_updating) {
            return false
        }

        this.removeItem(e);
    }

    removeEl(e) {
        if (this.is_updating) {
            return false
        }

        this.removeItem(e);
    }

    removeItem(item, remove = false) {
        const i = item.dataset.index;
        let obj = {
            index: i,
        };

        this.postData(item.dataset.url, obj).then((data) => {
            this.count = data.item_count;

            this.is_updating = false
            this.updateCart();
            if (this.count == 0) {
                this.close();
                return false
            } else {

                window.dispatchEvent(new CustomEvent("cart-update"));
            }
        });
    }
    open() {

        this.updateCart();
    }
    open() {

        this.updateCart();
        this.container.classList.remove("close");
    }
    close() {
        this.container.classList.add("close");
    }
    updateCart() {
        if (this.isupdating) {
            return false;
        }
        this.isupdating = true;
        fetch(this.cart_url)
            .then((response) => {
                // The API call was successful!
                return response.text();
            })
            .then((html) => {
                this.isupdating = false;
                //this.updateCount();

                this.wrapper.innerHTML = html;
                this.updateCount()
                this.bindActions();
            })
            .catch((err) => {
                // There was an error
                console.warn("Something went wrong.", err);
            });
    }

    updateCount() {
        if (this.isupdating) {
            return false;
        }
        this.isupdating = true;
        // console.log("count");
        fetch(this.info_url)
            .then((response) => {
                // The API call was successful!
                return response.json();
            })
            .then((json) => {

                this.isupdating = false;
                // console.log(json);
                this.subtotal.innerHTML = json.total.toLocaleString();
                this.cartCounter.innerHTML = json.item_count;
            })
            .catch((err) => {
                // There was an error
                console.warn("Something went wrong.", err);
            });
    }
    async postData(url = "", data = {}) {
        this.is_updating = true
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
                    .content,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
        });
        return response.json();
    }
}